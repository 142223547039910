<template>
    <div>
        <Service v-if="ShowService" @closeService="ShowService = false" />
        <Privacy v-if="ShowPrivacy" @closePrivacy="ShowPrivacy = false" />
        <LocationBased v-if="ShowLocationBased" @closeLocationBased="ShowLocationBased = false" />
        <div class="mo_space_header">
            <div class="mo_header_box">
                <a>
                    <img src="/media/images/icon_back_24.svg" @click="backspace" />
                </a>
                <div></div>
            </div>
        </div>
        <section class="social_login social_wrapper">
            <div class="social_login_box">
                <div class="progress-step">
                    <ul class="progress-step-list">
                        <!-- <li class="progress-step-item complete"><span>1</span></li>
            <li><img src="/media/img/login/icon_agree_dot_color.svg"></li> -->
                        <li class="current-item"><span>1</span></li>
                        <li><img src="/media/img/login/icon_agree_dot.svg" /></li>
                        <li><span class="social_agree naxt-item">2</span></li>
                    </ul>
                </div>

                <h2>약관에 동의해주세요.</h2>
                <h3 v-if="comeFrom === 'kakao'">카카오 계정으로 어플레이즈를 이용하실 수 있습니다.</h3>
                <h3 v-else-if="comeFrom === 'naver'">네이버 계정으로 어플레이즈를 이용하실 수 있습니다.</h3>
                <h3 v-else>애플 계정으로 어플레이즈를 이용하실 수 있습니다.</h3>
                <div class="social-ac-wrap">
                    <div>
                        <img :src="'/media/img/login/icon_social_' + comeFrom + '.svg'" />
                        <span class="social-ac">{{ email }}</span>
                        <!-- <img src="/media/img/login/icon_social_naver.svg">
            <img src="/media/img/login/icon_social_email.svg"> -->
                    </div>
                </div>
                <!-- <div class="change-social">
          <p>혹시 다른 {{ comeFrom }} 계정으로 가입을 원하시나요?</p>
          <p @click="changeSocial">다른 계정으로 가입하기</p>
          <div id="naverIdLogin" style="display: none;"></div>
        </div> -->
                <div style="margin: 0px;" class="agree_box">
                    <checkboxAgreeAll v-model="agreeAll" @input="agreeAll1()" />
                    <p style="margin-bottom: 0px">약관 전체 동의</p>
                </div>
                <div class="agree_txt">
                    <ul style="margin-bottom:0px;">
                        <li>
                            <div>
                                <checkboxService v-model="service" @input="agreeService()" />
                                <p style="margin-bottom: 0px">[필수] 사이트 이용약관</p>
                            </div>
                        </li>
                        <li><img src="/media/img/join_agree_icon.png" alt="" @click="ShowService = true" /></li>
                    </ul>
                    <ul style="margin-bottom:0px;">
                        <li>
                            <div>
                                <checkboxLocationBased v-model="locationBased" @input="agreeLocationBased()" />
                                <p style="margin-bottom: 0px">[필수] 위치기반서비스 이용약관</p>
                            </div>
                        </li>
                        <li><img src="/media/img/join_agree_icon.png" alt="" @click="ShowLocationBased = true" /></li>
                    </ul>
                    <ul style="margin-bottom:0px;">
                        <li>
                            <div>
                                <checkboxPrivacy v-model="privacy" @input="agreePrivacy()" />
                                <p style="margin-bottom: 0px">[필수] 개인정보 처리방침</p>
                            </div>
                        </li>
                        <li><img src="/media/img/join_agree_icon.png" alt="" @click="ShowPrivacy = true" /></li>
                    </ul>
                    <ul style="margin-bottom:0px;">
                        <li>
                            <div>
                                <checkboxMaketing v-model="marketing" @input="agreeMarketing()" />
                                <p style="margin-bottom: 0px">[선택] 마케팅 정보 수신</p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="btn-area">
                    <button
                        class="btn-next"
                        :style="{
                            'pointer-events': service && locationBased && privacy ? '' : 'none',
                            opacity: service && locationBased && privacy ? '1' : '0.3'
                        }"
                        @click="saveUserInfo"
                    >
                        다음
                    </button>
                    <button style="visibility:hidden" disabled></button>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import Service from '@/components/modal/SignUp/Service.vue';
import LocationBased from '@/components/modal/SignUp/LocationBased.vue';
import Privacy from '@/components/modal/SignUp/Privacy.vue';
import checkboxAgreeAll from '../../components/checkbox/checkboxAgreeAll.vue';
import checkboxService from '../../components/checkbox/checkboxService.vue';
import checkboxLocationBased from '../../components/checkbox/checkboxLocationBased.vue';
import checkboxPrivacy from '../../components/checkbox/checkboxPrivacy.vue';
import checkboxMaketing from '../../components/checkbox/checkboxMaketing.vue';
// import { naverService } from '@/assets/js/naverLogin.js';
import VueCookies from 'vue-cookies';
import router from '@/router';
import axios from 'axios';

export default defineComponent({
  props: ['userInfo', 'backStatus'],

  data () {
    return {
      ShowService: false,
      ShowLocationBased: false,
      ShowPrivacy: false,
      service: false,
      locationBased: false,
      privacy: false,
      marketing: false,
      agreeAll: false,
      userId: null,
      comeFrom: null,
      email: null
    };
  },

  components: {
    Service,
    LocationBased,
    Privacy,
    checkboxAgreeAll,
    checkboxPrivacy,
    checkboxService,
    checkboxMaketing,
    checkboxLocationBased
  },

  mounted () {
    if (this.backStatus !== 'Y') {
      router.replace({ name: 'Login' });
    }
    // naverService().setNaver();
    this.userId = VueCookies.get('userId');
    this.comeFrom = this.userInfo.comeFrom;
    this.email = this.userInfo.email;
  },

  methods: {
    generateRandomString (num) {
      // 네이버 인증 state 난수생성
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
      let result = '';
      const charactersLength = characters.length;
      for (let i = 0; i < num; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },

    changeSocial () {
      if (this.comeFrom === 'kakao') {
        const url = window.location.href;
        const uri =
          url.indexOf('localhost') > 0 || url.lastIndexOf('dev') > 0
            ? process.env.VUE_APP_DEV_CALLBACK_URL
            : process.env.VUE_APP_PRO_CALLBACK_URL;
        const crientId =
          url.indexOf('localhost') > 0 || url.lastIndexOf('dev') > 0
            ? process.env.VUE_APP_DEV_KAKAO_CRIENT_ID
            : process.env.VUE_APP_PRO_KAKAO_CRIENT_ID;

        // a 태그 생성
        const tag = document.createElement('a');
        tag.href =
          'https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=' +
          crientId +
          '&redirect_uri=' +
          uri +
          '&prompt=login';

        // 카카오 로그인 페이지로 이동
        tag.click();
      } else {
        // 현재 주소 가져오기
        const url = window.location.href;
        // 주소에 따른 콜백 주소 변경
        const uri =
          url.indexOf('localhost') > 0 || url.lastIndexOf('dev') > 0
            ? process.env.VUE_APP_DEV_CALLBACK_URL
            : process.env.VUE_APP_PRO_CALLBACK_URL;
        const crientId =
          url.indexOf('localhost') > 0 || url.lastIndexOf('dev') > 0
            ? process.env.VUE_APP_DEV_NAVER_CRIENT_ID
            : process.env.VUE_APP_PRO_NAVER_CRIENT_ID;
        // state 난수생성
        const state = this.generateRandomString(20);
        const tag = document.createElement('a');
        tag.href =
          'https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=' +
          crientId +
          '&redirect_uri=' +
          uri +
          '&state=' +
          state;
        tag.click();
      }
    },

    agreeAll1 () {
      // 약관 전체
      if (this.agreeAll) {
        this.service = true;
        this.locationBased = true;
        this.privacy = true;
        this.marketing = true;
      } else {
        this.service = false;
        this.locationBased = false;
        this.privacy = false;
        this.marketing = false;
      }
    },

    agreeService () {
      // 서비스 이용약관
      this.service === true && this.locationBased === true && this.privacy === true && this.marketing === true
        ? (this.agreeAll = true)
        : (this.agreeAll = false);
    },

    agreeLocationBased () {
      // 위치기반서비스 이용약관
      this.service === true && this.locationBased === true && this.privacy === true && this.marketing === true
        ? (this.agreeAll = true)
        : (this.agreeAll = false);
    },

    agreePrivacy () {
      // 개인정보 처리방침
      this.service === true && this.locationBased === true && this.privacy === true && this.marketing === true
        ? (this.agreeAll = true)
        : (this.agreeAll = false);
    },

    agreeMarketing () {
      // 마케팅 수신
      this.service === true && this.locationBased === true && this.privacy === true && this.marketing === true
        ? (this.agreeAll = true)
        : (this.agreeAll = false);
    },

    saveUserInfo () {
      const userId = this.userId;
      const marketing = this.marketing ? 'Y' : 'N';

      axios
        .post('/api/social/updatePolicy', { userId, marketing })
        .then(res => {
          if (res.data.result === 1) {
            router.push({ name: 'SocialVaild', params: { backStatus: 'Y' } });
          }
        })
        .catch(e => {
          console.log(e);
        });
    },

    backspace () {
      history.back();
    }
  }
});
</script>

<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/social.css"></style>
<style src="@/assets/css/font.css"></style>
